import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import config from "../../../config"

import HeaderResources from "../../../components/header_resources"

const validationSchema = Yup.object().shape({
  YourName: Yup.string().required("This is a required field"),
  EmailAddress: Yup.string()
    .email("Enter valid email address")
    .required("This is a required field"),
  row_r1cb67dd744a94add9a9bb5676b4e6a4a: Yup.string().required("This is a required field"),
  row_r11338bc370b547069f433a63e253155c: Yup.string().required("This is a required field"),
  row_rb79268064f6f449cae0ed7ab9fd83106: Yup.string().required("This is a required field"),
  row_r316e6020a4654a6f802585b83bbee88b: Yup.string().required("This is a required field"),
  row_r84fd6f6d81234f579db1e52e7f863c7f: Yup.string().required("This is a required field"),
  row_rad405627ce284d5d9f35aabf7e6e7b79: Yup.string().required("This is a required field"),
  row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6: Yup.string().required("This is a required field"),
  row_r716ca4662e094b75a6f2d0126db20a5b: Yup.string().required("This is a required field"),
  row_rfbe8158d1f0741b386b781170dd5ab12: Yup.string().required("This is a required field"),
  row_r06d411853ff14b5b88d756bbaf4b334d: Yup.string().required("This is a required field"),
  row_r1a5d02c541504bad9088155ce81d9097: Yup.string().required("This is a required field"),
  row_r92c027a0f79e455ba21ed063ccc14940: Yup.string().required("This is a required field"),
  row_rc0c217e942eb4bdab17d3fde3654c252: Yup.string().required("This is a required field"),
  row_rfb958dc9e81140ecbb5bbf4ac864d67c: Yup.string().required("This is a required field"),
  row_rc61b7495a27a43e082145cf16172a2b4: Yup.string().required("This is a required field"),
  row_r7282ee7b01cb4ba6b7d8ca4121b9be18: Yup.string().required("This is a required field"),
  row_r1f757ba2580b42afb95a77e5054b7840: Yup.string().required("This is a required field"),
  row_r8d541db24c884860b74b9d888d971d33: Yup.string().required("This is a required field"),
  row_rfc505664b53742709ff5f0e4348173f3: Yup.string().required("This is a required field"),
  row_r7d458cee645b4e099ad957bee4b65dc8: Yup.string().required("This is a required field"),
  row_r7f01b22fbcad4338bfa3f2f3cbde3977: Yup.string().required("This is a required field"),
  row_ra4788b4bf8e14d11bff8b7f7bed1c093: Yup.string().required("This is a required field"),
  row_rd7782a4011f44a96b0fbfc813a718d1b: Yup.string().required("This is a required field"),
  row_r94303efb31cb4350b9e334753ff28f22: Yup.string().required("This is a required field"),
})

export default function InvestmentAdvisory() {
  const heroTitle = "Investment Advisory Questionnaire"

  const { executeRecaptcha } = useGoogleReCaptcha()
  // const [showSuccessDiv, setShowSuccessDiv] = useState(false)
  const showSuccessDiv = false
  const [errorMessage, setErrorMessage] = useState("")

  const initStateObj = {
    YourName: "",
    EmailAddress: "",
    row_r1cb67dd744a94add9a9bb5676b4e6a4a: "",
    row_r11338bc370b547069f433a63e253155c: "",
    row_rb79268064f6f449cae0ed7ab9fd83106: "",
    row_r316e6020a4654a6f802585b83bbee88b: "",
    row_r84fd6f6d81234f579db1e52e7f863c7f: "",
    row_rad405627ce284d5d9f35aabf7e6e7b79: "",
    row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6: "",
    row_r716ca4662e094b75a6f2d0126db20a5b: "",
    row_rfbe8158d1f0741b386b781170dd5ab12: "",
    row_r06d411853ff14b5b88d756bbaf4b334d: "",
    row_r1a5d02c541504bad9088155ce81d9097: "",
    row_r92c027a0f79e455ba21ed063ccc14940: "",
    row_rc0c217e942eb4bdab17d3fde3654c252: "",
    row_rfb958dc9e81140ecbb5bbf4ac864d67c: "",
    row_rc61b7495a27a43e082145cf16172a2b4: "",
    row_r7282ee7b01cb4ba6b7d8ca4121b9be18: "",
    row_r1f757ba2580b42afb95a77e5054b7840: "",
    row_r8d541db24c884860b74b9d888d971d33: "",
    row_rfc505664b53742709ff5f0e4348173f3: "",
    row_r7d458cee645b4e099ad957bee4b65dc8: "",
    row_r7f01b22fbcad4338bfa3f2f3cbde3977: "",
    row_ra4788b4bf8e14d11bff8b7f7bed1c093: "",
    row_rd7782a4011f44a96b0fbfc813a718d1b: "",
    row_r94303efb31cb4350b9e334753ff28f22: "",
  }

  const formSubmitHandler = async (values, resetFormHandler) => {
    setErrorMessage("")

    if (!executeRecaptcha) {
      return
    }
    const captchaResponse = await executeRecaptcha("investment_advisory")

    var bodyFormData = new FormData()

    Object.keys(values).forEach(data => {
      bodyFormData.append(data, values[data])
    })

    bodyFormData.append("g-recaptcha-response", captchaResponse)

    try {
      const investmentApiResponse = await axios({
        method: "post",
        url: config.API_BASE_URL + "investment-advice-submit.php",
        data: bodyFormData,
      })

      if (investmentApiResponse.data.success) {
        //setShowSuccessDiv(true)
        resetFormHandler()
        navigate("/resources/tools/thankyou", {
          state: {
            form: "investment-advisory",
          },
        })
      } else {
        setErrorMessage(investmentApiResponse.data.errors)
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="innerpage--content innerpage--content-sm pb-0">
        <div class="w-100 mb-5 sm:mmb-5">
          <Link class="back--home" to="/resources/tools">
            <i class="fas fa-chevron-left"></i> <span>Back to Tools</span>
          </Link>
        </div>

        <h2 class="h2 h2-sm">{heroTitle}</h2>

        <div id="hlExcelRenderArea" class="form-horizontal form-fix-bottom-padding ___fmhc">
          <div class="container-fluid p-0">
            <div class=" form-control-static">
              <p>
                Your feedback helps us to improve our business by making it more valuable and
                rewarding for you.
              </p>
            </div>

            <Formik
              initialValues={initStateObj}
              onSubmit={(values, { resetForm }) => {
                formSubmitHandler(values, resetForm)
              }}
              validationSchema={validationSchema}
            >
              {({ handleChange, values, handleSubmit, errors, touched, handleBlur }) => (
                <form
                  class="form-horizontal mt-4"
                  id="investment-advisory-form"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div class="row form-group">
                    <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                      <label htmlFor="YourName">
                        1. Please provide your name: <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="YourName"
                        id="YourName"
                        value={values.YourName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="YourName" component="span" className="help-block" />
                    </div>
                    <div class="col-12 col-md-6 col-sm-6 col-xs-6">
                      <label htmlFor="EmailAddress">
                        2. Please provide Email Address <span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="EmailAddress"
                        id="EmailAddress"
                        value={values.EmailAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="EmailAddress" component="span" className="help-block" />
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="group_label_r1cb67dd744a94add9a9bb5676b4e6a4a">
                        3. Please rate each of the following attributes, according to how valuable
                        this service is to you. <span class="required">*</span>
                      </label>

                      <div class="office-form-matrix-group">
                        <div class="office-form-matrix-header">
                          <div class="office-form-matrix-cell"></div>
                          <div class="office-form-matrix-cell">
                            <span>Not valuable</span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span></span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span>Somewhat valuable</span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span></span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span>Very valuable</span>
                          </div>
                        </div>
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r1cb67dd744a94add9a9bb5676b4e6a4a"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r1cb67dd744a94add9a9bb5676b4e6a4a"
                          >
                            <span>
                              I need to completely trust that my financial adviser will put my needs
                              first and foremost
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to completely trust that my financial adviser will put my needs first and foremost, Not valuable"
                                  name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to completely trust that my financial adviser will put my needs first and foremost, "
                                  name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to completely trust that my financial adviser will put my needs first and foremost, Somewhat valuable"
                                  name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to completely trust that my financial adviser will put my needs first and foremost, "
                                  name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to completely trust that my financial adviser will put my needs first and foremost, Very valuable"
                                  name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r1cb67dd744a94add9a9bb5676b4e6a4a"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r11338bc370b547069f433a63e253155c"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r11338bc370b547069f433a63e253155c"
                          >
                            <span>
                              I need to know my financial plan is continuously monitored and updated
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know my financial plan is continuously monitored and updated, Not valuable"
                                  name="row_r11338bc370b547069f433a63e253155c"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know my financial plan is continuously monitored and updated, "
                                  name="row_r11338bc370b547069f433a63e253155c"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know my financial plan is continuously monitored and updated, Somewhat valuable"
                                  name="row_r11338bc370b547069f433a63e253155c"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know my financial plan is continuously monitored and updated, "
                                  name="row_r11338bc370b547069f433a63e253155c"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know my financial plan is continuously monitored and updated, Very valuable"
                                  name="row_r11338bc370b547069f433a63e253155c"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r11338bc370b547069f433a63e253155c"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rb79268064f6f449cae0ed7ab9fd83106"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rb79268064f6f449cae0ed7ab9fd83106"
                          >
                            <span>
                              I need to feel a personal connection with my financial adviser{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel a personal connection with my financial adviser , Not valuable"
                                  name="row_rb79268064f6f449cae0ed7ab9fd83106"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel a personal connection with my financial adviser , "
                                  name="row_rb79268064f6f449cae0ed7ab9fd83106"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel a personal connection with my financial adviser , Somewhat valuable"
                                  name="row_rb79268064f6f449cae0ed7ab9fd83106"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel a personal connection with my financial adviser , "
                                  name="row_rb79268064f6f449cae0ed7ab9fd83106"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel a personal connection with my financial adviser , Very valuable"
                                  name="row_rb79268064f6f449cae0ed7ab9fd83106"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rb79268064f6f449cae0ed7ab9fd83106"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r316e6020a4654a6f802585b83bbee88b"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r316e6020a4654a6f802585b83bbee88b"
                          >
                            <span>
                              I need an expert perspective to guide all of my investment decisions{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need an expert perspective to guide all of my investment decisions , Not valuable"
                                  name="row_r316e6020a4654a6f802585b83bbee88b"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need an expert perspective to guide all of my investment decisions , "
                                  name="row_r316e6020a4654a6f802585b83bbee88b"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need an expert perspective to guide all of my investment decisions , Somewhat valuable"
                                  name="row_r316e6020a4654a6f802585b83bbee88b"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need an expert perspective to guide all of my investment decisions , "
                                  name="row_r316e6020a4654a6f802585b83bbee88b"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need an expert perspective to guide all of my investment decisions , Very valuable"
                                  name="row_r316e6020a4654a6f802585b83bbee88b"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r316e6020a4654a6f802585b83bbee88b"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r84fd6f6d81234f579db1e52e7f863c7f"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r84fd6f6d81234f579db1e52e7f863c7f"
                          >
                            <span>
                              I need complete transparency whenever changes are made to my portfolio{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need complete transparency whenever changes are made to my portfolio , Not valuable"
                                  name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need complete transparency whenever changes are made to my portfolio , "
                                  name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need complete transparency whenever changes are made to my portfolio , Somewhat valuable"
                                  name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need complete transparency whenever changes are made to my portfolio , "
                                  name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need complete transparency whenever changes are made to my portfolio , Very valuable"
                                  name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r84fd6f6d81234f579db1e52e7f863c7f"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rad405627ce284d5d9f35aabf7e6e7b79"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rad405627ce284d5d9f35aabf7e6e7b79"
                          >
                            <span>
                              {" "}
                              I need to have access to a financial expert whenever I need it{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need to have access to a financial expert whenever I need it , Not valuable"
                                  name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need to have access to a financial expert whenever I need it , "
                                  name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need to have access to a financial expert whenever I need it , Somewhat valuable"
                                  name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need to have access to a financial expert whenever I need it , "
                                  name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need to have access to a financial expert whenever I need it , Very valuable"
                                  name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rad405627ce284d5d9f35aabf7e6e7b79"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                          >
                            <span>
                              I need to have professional financial help so I can spend my time on
                              other things that matter to me
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to have professional financial help so I can spend my time on other things that matter to me, Not valuable"
                                  name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to have professional financial help so I can spend my time on other things that matter to me, "
                                  name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to have professional financial help so I can spend my time on other things that matter to me, Somewhat valuable"
                                  name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to have professional financial help so I can spend my time on other things that matter to me, "
                                  name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to have professional financial help so I can spend my time on other things that matter to me, Very valuable"
                                  name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r0ab57ea52b4c4bb4b5faf5d4129fd7c6"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r716ca4662e094b75a6f2d0126db20a5b"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r716ca4662e094b75a6f2d0126db20a5b"
                          >
                            <span>
                              I need to feel like I have complete control over all of my financial
                              decisions
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have complete control over all of my financial decisions, Not valuable"
                                  name="row_r716ca4662e094b75a6f2d0126db20a5b"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have complete control over all of my financial decisions, "
                                  name="row_r716ca4662e094b75a6f2d0126db20a5b"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have complete control over all of my financial decisions, Somewhat valuable"
                                  name="row_r716ca4662e094b75a6f2d0126db20a5b"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have complete control over all of my financial decisions, "
                                  name="row_r716ca4662e094b75a6f2d0126db20a5b"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have complete control over all of my financial decisions, Very valuable"
                                  name="row_r716ca4662e094b75a6f2d0126db20a5b"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r716ca4662e094b75a6f2d0126db20a5b"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rfbe8158d1f0741b386b781170dd5ab12"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rfbe8158d1f0741b386b781170dd5ab12"
                          >
                            <span>
                              I need regular proactive outreach to keep me updated about my finances{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need regular proactive outreach to keep me updated about my finances , Not valuable"
                                  name="row_rfbe8158d1f0741b386b781170dd5ab12"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need regular proactive outreach to keep me updated about my finances , "
                                  name="row_rfbe8158d1f0741b386b781170dd5ab12"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need regular proactive outreach to keep me updated about my finances , Somewhat valuable"
                                  name="row_rfbe8158d1f0741b386b781170dd5ab12"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need regular proactive outreach to keep me updated about my finances , "
                                  name="row_rfbe8158d1f0741b386b781170dd5ab12"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need regular proactive outreach to keep me updated about my finances , Very valuable"
                                  name="row_rfbe8158d1f0741b386b781170dd5ab12"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rfbe8158d1f0741b386b781170dd5ab12"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r06d411853ff14b5b88d756bbaf4b334d"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r06d411853ff14b5b88d756bbaf4b334d"
                          >
                            <span>
                              I need to feel like I have taken charge of my financial future{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have taken charge of my financial future , Not valuable"
                                  name="row_r06d411853ff14b5b88d756bbaf4b334d"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have taken charge of my financial future , "
                                  name="row_r06d411853ff14b5b88d756bbaf4b334d"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have taken charge of my financial future , Somewhat valuable"
                                  name="row_r06d411853ff14b5b88d756bbaf4b334d"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have taken charge of my financial future , "
                                  name="row_r06d411853ff14b5b88d756bbaf4b334d"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel like I have taken charge of my financial future , Very valuable"
                                  name="row_r06d411853ff14b5b88d756bbaf4b334d"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r06d411853ff14b5b88d756bbaf4b334d"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r1a5d02c541504bad9088155ce81d9097"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r1a5d02c541504bad9088155ce81d9097"
                          >
                            <span>
                              I need to know exactly how much money I’m paying my financial adviser{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know exactly how much money I’m paying my financial adviser , Not valuable"
                                  name="row_r1a5d02c541504bad9088155ce81d9097"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know exactly how much money I’m paying my financial adviser , "
                                  name="row_r1a5d02c541504bad9088155ce81d9097"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know exactly how much money I’m paying my financial adviser , Somewhat valuable"
                                  name="row_r1a5d02c541504bad9088155ce81d9097"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know exactly how much money I’m paying my financial adviser , "
                                  name="row_r1a5d02c541504bad9088155ce81d9097"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know exactly how much money I’m paying my financial adviser , Very valuable"
                                  name="row_r1a5d02c541504bad9088155ce81d9097"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r1a5d02c541504bad9088155ce81d9097"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r92c027a0f79e455ba21ed063ccc14940"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r92c027a0f79e455ba21ed063ccc14940"
                          >
                            <span> I need round-the-clock online access to my account </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need round-the-clock online access to my account , Not valuable"
                                  name="row_r92c027a0f79e455ba21ed063ccc14940"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need round-the-clock online access to my account , "
                                  name="row_r92c027a0f79e455ba21ed063ccc14940"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need round-the-clock online access to my account , Somewhat valuable"
                                  name="row_r92c027a0f79e455ba21ed063ccc14940"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need round-the-clock online access to my account , "
                                  name="row_r92c027a0f79e455ba21ed063ccc14940"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label=" I need round-the-clock online access to my account , Very valuable"
                                  name="row_r92c027a0f79e455ba21ed063ccc14940"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r92c027a0f79e455ba21ed063ccc14940"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rc0c217e942eb4bdab17d3fde3654c252"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rc0c217e942eb4bdab17d3fde3654c252"
                          >
                            <span>
                              I need to feel that I am on track to meet my financial goals{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel that I am on track to meet my financial goals , Not valuable"
                                  name="row_rc0c217e942eb4bdab17d3fde3654c252"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel that I am on track to meet my financial goals , "
                                  name="row_rc0c217e942eb4bdab17d3fde3654c252"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel that I am on track to meet my financial goals , Somewhat valuable"
                                  name="row_rc0c217e942eb4bdab17d3fde3654c252"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel that I am on track to meet my financial goals , "
                                  name="row_rc0c217e942eb4bdab17d3fde3654c252"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel that I am on track to meet my financial goals , Very valuable"
                                  name="row_rc0c217e942eb4bdab17d3fde3654c252"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rc0c217e942eb4bdab17d3fde3654c252"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rfb958dc9e81140ecbb5bbf4ac864d67c"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rfb958dc9e81140ecbb5bbf4ac864d67c"
                          >
                            <span>
                              I need the assurance of guaranteed income in retirement and I am
                              willing to exchange a portion of my portfolio in return for it
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need the assurance of guaranteed income in retirement and I am willing to exchange a portion of my portfolio in return for it, Not valuable"
                                  name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need the assurance of guaranteed income in retirement and I am willing to exchange a portion of my portfolio in return for it, "
                                  name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need the assurance of guaranteed income in retirement and I am willing to exchange a portion of my portfolio in return for it, Somewhat valuable"
                                  name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need the assurance of guaranteed income in retirement and I am willing to exchange a portion of my portfolio in return for it, "
                                  name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need the assurance of guaranteed income in retirement and I am willing to exchange a portion of my portfolio in return for it, Very valuable"
                                  name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rfb958dc9e81140ecbb5bbf4ac864d67c"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rc61b7495a27a43e082145cf16172a2b4"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rc61b7495a27a43e082145cf16172a2b4"
                          >
                            <span>I need a financial plan that offers me financial freedom </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a financial plan that offers me financial freedom , Not valuable"
                                  name="row_rc61b7495a27a43e082145cf16172a2b4"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a financial plan that offers me financial freedom , "
                                  name="row_rc61b7495a27a43e082145cf16172a2b4"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a financial plan that offers me financial freedom , Somewhat valuable"
                                  name="row_rc61b7495a27a43e082145cf16172a2b4"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a financial plan that offers me financial freedom , "
                                  name="row_rc61b7495a27a43e082145cf16172a2b4"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a financial plan that offers me financial freedom , Very valuable"
                                  name="row_rc61b7495a27a43e082145cf16172a2b4"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rc61b7495a27a43e082145cf16172a2b4"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                          >
                            <span>
                              I need to know that my survivors will have help navigating financial
                              decisions after I am gone
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know that my survivors will have help navigating financial decisions after I am gone, Not valuable"
                                  name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know that my survivors will have help navigating financial decisions after I am gone, "
                                  name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know that my survivors will have help navigating financial decisions after I am gone, Somewhat valuable"
                                  name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know that my survivors will have help navigating financial decisions after I am gone, "
                                  name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to know that my survivors will have help navigating financial decisions after I am gone, Very valuable"
                                  name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r7282ee7b01cb4ba6b7d8ca4121b9be18"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r1f757ba2580b42afb95a77e5054b7840"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r1f757ba2580b42afb95a77e5054b7840"
                          >
                            <span>
                              I need to feel completely reassured that things will be okay,
                              including during financial market downturns{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel completely reassured that things will be okay, including during financial market downturns , Not valuable"
                                  name="row_r1f757ba2580b42afb95a77e5054b7840"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel completely reassured that things will be okay, including during financial market downturns , "
                                  name="row_r1f757ba2580b42afb95a77e5054b7840"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel completely reassured that things will be okay, including during financial market downturns , Somewhat valuable"
                                  name="row_r1f757ba2580b42afb95a77e5054b7840"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel completely reassured that things will be okay, including during financial market downturns , "
                                  name="row_r1f757ba2580b42afb95a77e5054b7840"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to feel completely reassured that things will be okay, including during financial market downturns , Very valuable"
                                  name="row_r1f757ba2580b42afb95a77e5054b7840"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r1f757ba2580b42afb95a77e5054b7840"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r8d541db24c884860b74b9d888d971d33"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r8d541db24c884860b74b9d888d971d33"
                          >
                            <span>
                              I need to protect myself against unexpected events that could
                              negatively impact my investments{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect myself against unexpected events that could negatively impact my investments , Not valuable"
                                  name="row_r8d541db24c884860b74b9d888d971d33"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect myself against unexpected events that could negatively impact my investments , "
                                  name="row_r8d541db24c884860b74b9d888d971d33"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect myself against unexpected events that could negatively impact my investments , Somewhat valuable"
                                  name="row_r8d541db24c884860b74b9d888d971d33"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect myself against unexpected events that could negatively impact my investments , "
                                  name="row_r8d541db24c884860b74b9d888d971d33"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect myself against unexpected events that could negatively impact my investments , Very valuable"
                                  name="row_r8d541db24c884860b74b9d888d971d33"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r8d541db24c884860b74b9d888d971d33"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rfc505664b53742709ff5f0e4348173f3"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rfc505664b53742709ff5f0e4348173f3"
                          >
                            <span>
                              I need to protect my financial well-being in the event I experience
                              diminished decision-making capabilities in my later years{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect my financial well-being in the event I experience diminished decision-making capabilities in my later years , Not valuable"
                                  name="row_rfc505664b53742709ff5f0e4348173f3"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect my financial well-being in the event I experience diminished decision-making capabilities in my later years , "
                                  name="row_rfc505664b53742709ff5f0e4348173f3"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect my financial well-being in the event I experience diminished decision-making capabilities in my later years , Somewhat valuable"
                                  name="row_rfc505664b53742709ff5f0e4348173f3"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect my financial well-being in the event I experience diminished decision-making capabilities in my later years , "
                                  name="row_rfc505664b53742709ff5f0e4348173f3"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to protect my financial well-being in the event I experience diminished decision-making capabilities in my later years , Very valuable"
                                  name="row_rfc505664b53742709ff5f0e4348173f3"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rfc505664b53742709ff5f0e4348173f3"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r7d458cee645b4e099ad957bee4b65dc8"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r7d458cee645b4e099ad957bee4b65dc8"
                          >
                            <span>
                              I need a customized financial plan that covers more than just my
                              investments
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a customized financial plan that covers more than just my investments, Not valuable"
                                  name="row_r7d458cee645b4e099ad957bee4b65dc8"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a customized financial plan that covers more than just my investments, "
                                  name="row_r7d458cee645b4e099ad957bee4b65dc8"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a customized financial plan that covers more than just my investments, Somewhat valuable"
                                  name="row_r7d458cee645b4e099ad957bee4b65dc8"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a customized financial plan that covers more than just my investments, "
                                  name="row_r7d458cee645b4e099ad957bee4b65dc8"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a customized financial plan that covers more than just my investments, Very valuable"
                                  name="row_r7d458cee645b4e099ad957bee4b65dc8"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r7d458cee645b4e099ad957bee4b65dc8"
                          component="span"
                          className="help-block"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row form-group">
                    <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="none">
                        4. Continued... <span class="required">*</span>
                      </label>

                      <div class="office-form-matrix-group">
                        <div class="office-form-matrix-header">
                          <div class="office-form-matrix-cell"></div>
                          <div class="office-form-matrix-cell">
                            <span>Not valuable </span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span></span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span>Somewhat valuable</span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span></span>
                          </div>
                          <div class="office-form-matrix-cell">
                            <span>Very valuable</span>
                          </div>
                        </div>
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r7f01b22fbcad4338bfa3f2f3cbde3977"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r7f01b22fbcad4338bfa3f2f3cbde3977"
                          >
                            <span>I need help balancing my spending and saving </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need help balancing my spending and saving , Not valuable "
                                  name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need help balancing my spending and saving , "
                                  name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need help balancing my spending and saving , Somewhat valuable"
                                  name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need help balancing my spending and saving , "
                                  name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need help balancing my spending and saving , Very valuable"
                                  name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r7f01b22fbcad4338bfa3f2f3cbde3977"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_ra4788b4bf8e14d11bff8b7f7bed1c093"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_ra4788b4bf8e14d11bff8b7f7bed1c093"
                          >
                            <span>
                              I need to expand my knowledge of investments and personal finance
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to expand my knowledge of investments and personal finance, Not valuable "
                                  name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to expand my knowledge of investments and personal finance, "
                                  name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to expand my knowledge of investments and personal finance, Somewhat valuable"
                                  name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to expand my knowledge of investments and personal finance, "
                                  name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to expand my knowledge of investments and personal finance, Very valuable"
                                  name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_ra4788b4bf8e14d11bff8b7f7bed1c093"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_rd7782a4011f44a96b0fbfc813a718d1b"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_rd7782a4011f44a96b0fbfc813a718d1b"
                          >
                            <span>
                              I need to maximize my investment returns, even at the risk of
                              substantial losses in the value of my portfolio{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to maximize my investment returns, even at the risk of substantial losses in the value of my portfolio , Not valuable "
                                  name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to maximize my investment returns, even at the risk of substantial losses in the value of my portfolio , "
                                  name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to maximize my investment returns, even at the risk of substantial losses in the value of my portfolio , Somewhat valuable"
                                  name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to maximize my investment returns, even at the risk of substantial losses in the value of my portfolio , "
                                  name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need to maximize my investment returns, even at the risk of substantial losses in the value of my portfolio , Very valuable"
                                  name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_rd7782a4011f44a96b0fbfc813a718d1b"
                          component="span"
                          className="help-block"
                        />
                        <div
                          class="office-form-matrix-row"
                          role="radiogroup"
                          aria-labelledby="group_label_r94303efb31cb4350b9e334753ff28f22"
                        >
                          <div
                            class="office-form-matrix-cell"
                            id="group_label_r94303efb31cb4350b9e334753ff28f22"
                          >
                            <span>
                              I need a neutral third party to facilitate financial discussions
                              between me and my spouse/partner or other family members{" "}
                            </span>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a neutral third party to facilitate financial discussions between me and my spouse/partner or other family members , Not valuable "
                                  name="row_r94303efb31cb4350b9e334753ff28f22"
                                  value="1"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a neutral third party to facilitate financial discussions between me and my spouse/partner or other family members , "
                                  name="row_r94303efb31cb4350b9e334753ff28f22"
                                  value="2"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a neutral third party to facilitate financial discussions between me and my spouse/partner or other family members , Somewhat valuable"
                                  name="row_r94303efb31cb4350b9e334753ff28f22"
                                  value="3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a neutral third party to facilitate financial discussions between me and my spouse/partner or other family members , "
                                  name="row_r94303efb31cb4350b9e334753ff28f22"
                                  value="4"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                          <div class="office-form-matrix-cell" tabindex="-1">
                            <div class="button-set">
                              <label>
                                <input
                                  type="radio"
                                  aria-label="I need a neutral third party to facilitate financial discussions between me and my spouse/partner or other family members , Very valuable"
                                  name="row_r94303efb31cb4350b9e334753ff28f22"
                                  value="5"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          name="row_r94303efb31cb4350b9e334753ff28f22"
                          component="span"
                          className="help-block"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="hr">
                    <hr />
                  </div>

                  <button
                    class="c-button c-button--blue c-button-radius c-button-inline c-button-lg"
                    type="submit"
                  >
                    Submit
                  </button>

                  {showSuccessDiv && (
                    <div class="alert alert-success mt-3" role="alert">
                      <strong>Thanks for the response. We'll get back to you.</strong>
                    </div>
                  )}

                  {errorMessage && (
                    <div class="alert alert-danger mt-3" role="alert">
                      <strong>{errorMessage}</strong>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </HeaderResources>
  )
}
